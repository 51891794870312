import store from '~/store';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '~/views/Dashboard').then(m => m.default || m);
const CustomDashboard = () =>
  import(/* webpackChunkName: "custom-dashboard" */ '~/views/CustomDashboard').then(m => m.default || m);
const CustomDashboardPosts = () =>
  import(/* webpackChunkName: "custom-dashboard" */ '~/views/CustomDashboard/Posts').then(m => m.default || m);
const Topics = () => import(/* webpackChunkName: "dashboard" */ '~/views/Topics').then(m => m.default || m);
const MyPosts = () => import(/* webpackChunkName: "dashboard" */ '~/views/MyPosts').then(m => m.default || m);

const Reports = () => import(/* webpackChunkName: "dashboard" */ '~/views/Report/index').then(m => m.default || m);
const ReportOverview = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Overview').then(m => m.default || m);
const DownloadReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Download').then(m => m.default || m);
const FacebookReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Facebook').then(m => m.default || m);
const InstagramReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Instagram').then(m => m.default || m);
const LinkedinReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Linkedin').then(m => m.default || m);
const ScheduleReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Schedule').then(m => m.default || m);
const TwitterReport = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Report/Twitter').then(m => m.default || m);
const Campaigns = () => import(/* webpackChunkName: "dashboard" */ '~/views/Campaigns').then(m => m.default || m);

const Automation = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/index').then(m => m.default || m);
const AutomationHome = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/home').then(m => m.default || m);
const AiAutomationHome = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/ai-home').then(m => m.default || m);
const AutomationList = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/list').then(m => m.default || m);
const AutomationCreate = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/create').then(m => m.default || m);
const AutomationView = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Automation/view').then(m => m.default || m);

const Planner = () => import(/* webpackChunkName: "dashboard" */ '~/views/Planner').then(m => m.default || m);
const PlannerCalender = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Planner/calendar').then(m => m.default || m);
const PlannerList = () => import(/* webpackChunkName: "dashboard" */ '~/views/Planner/list').then(m => m.default || m);

const Settings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Settings/Brand/index.vue').then(m => m.default || m);
const BrandSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/Home').then(m => m.default || m);
const TeamSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/Team').then(m => m.default || m);
const SocialAccountsSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/SocialAccounts').then(m => m.default || m);
const AudioAccountsSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/AudioAccounts').then(m => m.default || m);
const BlogAccountsSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/BlogAccounts').then(m => m.default || m);
const IntegrationsSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/Integrations').then(m => m.default || m);
const MiscellaneousSetting = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Brand/Miscellaneous').then(m => m.default || m);

const PostView = () => import(/* webpackChunkName: "dashboard" */ '~/views/ViewPost').then(m => m.default || m);

const Compose = () => import(/* webpackChunkName: "dashboard" */ '~/views/compose').then(m => m.default || m);
const ComposeAudio = () => import(/* webpackChunkName: "dashboard" */ '~/views/composeAudio').then(m => m.default || m);

const Draft = () => import(/* webpackChunkName: "draft-compose" */ '~/views/Draft').then(m => m.default || m);
const DraftOverview = () =>
  import(/* webpackChunkName: "draft-compose" */ '~/views/Draft/overview').then(m => m.default || m);
const DraftEdit = () => import(/* webpackChunkName: "draft-compose" */ '~/views/Draft/edit').then(m => m.default || m);
const DraftShare = () =>
  import(/* webpackChunkName: "draft-compose" */ '~/views/Draft/share').then(m => m.default || m);

// =============================== CREATE SINGLE POST =============================================

const Find = () => import(/* webpackChunkName: "find-content" */ '~/views/Find').then(m => m.default || m);
const FindArticles = () =>
  import(/* webpackChunkName: "find-content" */ '~/views/Find/articles').then(m => m.default || m);
const FindQuotes = () => import(/* webpackChunkName: "find-content" */ '~/views/Find/quotes').then(m => m.default || m);
const FindMemes = () => import(/* webpackChunkName: "find-content" */ '~/views/Find/memes').then(m => m.default || m);
const FindVideos = () => import(/* webpackChunkName: "find-content" */ '~/views/Find/videos').then(m => m.default || m);

const BannerDesign = () =>
  import(/* webpackChunkName: "banner-design" */ '~/views/BannerDesign').then(m => m.default || m);
const BannerDesignOverview = () =>
  import(/* webpackChunkName: "banner-design" */ '~/views/BannerDesign/overview').then(m => m.default || m);
const BannerDesigns = () =>
  import(/* webpackChunkName: "banner-design" */ '~/views/BannerDesign/designs').then(m => m.default || m);
const BannerDesignEdit = () =>
  import(/* webpackChunkName: "banner-design" */ '~/views/BannerDesign/edit').then(m => m.default || m);
const BannerDesignShare = () =>
  import(/* webpackChunkName: "banner-design" */ '~/views/BannerDesign/share').then(m => m.default || m);

// ================================== CAMPAIGN ============================================
const Campaign = () => import(/* webpackChunkName: "campaign" */ '~/views/Campaign').then(m => m.default || m);

const ViewCampaignPosts = () =>
  import(/* webpackChunkName: "campaign" */ '~/views/Campaign/View/posts').then(m => m.default || m);

const VideoComposer = () => import('~/views/VideoComposer/index').then(m => m.default || m);
const VideoComposerWizard = () => import('~/views/VideoComposer/wizard.vue').then(m => m.default || m);
const VideoComposerEditor = () => import('~/views/VideoComposer/editor.vue').then(m => m.default || m);
const VideoComposerList = () => import('~/views/VideoComposer/list.vue').then(m => m.default || m);
// ======================================================================================================

export default [
  {
    path: '',
    redirect: { name: 'dashboard' },
  },

  {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: 'client',
    component: CustomDashboard,
    alias: ['approver'],
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'custom-dashboard',
        redirect: () => {
          const isApprover = store.getters['accessControl/isUserApproverInWorkspace']();
          if (isApprover) return '/:workspace/approver/view/posts';
          return { name: 'custom-dashboard-posts' };
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'view/posts',
        alias: ['/:workspace/approver/view/posts'],
        name: 'custom-dashboard-posts',
        component: CustomDashboardPosts,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'video-composer',
    name: 'video-composer',
    component: VideoComposer,
    meta: {
      requiresAuth: true,
      showSidebar: false,
    },
  },
  {
    path: 'video-composer-wizard',
    name: 'video-composer-wizard',
    // component: VideoComposerWizard,
    component: () => import('~/views/VideoComposer/editor.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: false,
    },
  },
  {
    path: 'video-composer-editor',
    name: 'video-composer-editor',
    component: VideoComposerEditor,
    meta: {
      requiresAuth: true,
      showSidebar: false,
    },
  },
  {
    path: 'video-composer-list',
    name: 'video-composer-list',
    component: VideoComposerList,
    meta: {
      requiresAuth: true,
      showSidebar: false,
    },
  },
  {
    path: 'topics',
    name: 'topics',
    component: Topics,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: 'posts',
    name: 'posts',
    component: MyPosts,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: 'find',
    component: Find,
    redirect: { name: 'find.articles' },
    name: 'find',
    children: [
      {
        path: 'articles',
        name: 'find.articles',
        component: FindArticles,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'quotes',
        name: 'find.quotes',
        component: FindQuotes,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'memes',
        name: 'find.memes',
        component: FindMemes,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'videos',
        name: 'find.videos',
        component: FindVideos,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'designer',
    name: 'designer',
    redirect: { name: 'designer.overview' },
    component: BannerDesign,
    children: [
      {
        path: 'overview',
        name: 'designer.overview',
        component: BannerDesignOverview,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'designs',
        name: 'designer.designs',
        component: BannerDesigns,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id/edit',
        name: 'designer.edit',
        component: BannerDesignEdit,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: ':id/share',
        name: 'designer.share',
        component: BannerDesignShare,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'reports',
    name: 'reports',
    component: Reports,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'report.overview' },
    children: [
      {
        path: 'overview',
        name: 'report.overview',
        component: ReportOverview,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'facebook-report',
        name: 'report.facebook',
        component: FacebookReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'instagram-report',
        name: 'report.instagram',
        component: InstagramReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'linkedin-report',
        name: 'report.linkedin',
        component: LinkedinReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'twitter-report',
        name: 'report.twitter',
        component: TwitterReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'schedule-report',
        name: 'report.schedule',
        component: ScheduleReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'download-report',
        name: 'report.download',
        component: DownloadReport,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: 'automation',
    component: Automation,
    children: [
      {
        path: '',
        name: 'automation.home',
        component: AutomationHome,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'ai-automation',
        name: 'ai-automation.home',
        component: AiAutomationHome,
        meta: {
          requiresAuth: true,
          showSidebar: false,
        },
      },
      {
        path: ':type/:recipe',
        name: 'automation.listx',
        component: AutomationList,
        meta: {
          showSidebar: true,
          requiresAuth: true,
        },
      },
      {
        path: 'list/:type/:recipe',
        name: 'automation.list',
        component: AutomationList,
        meta: {
          showSidebar: true,
          requiresAuth: true,
        },
      },
      {
        path: ':type/:recipe/create',
        name: 'automation.create',
        component: AutomationCreate,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: ':type/:recipe/posts/:id',
        name: 'automation.posts',
        component: AutomationView,
        meta: {
          showSidebar: true,
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'post/:id',
    name: 'post.view',
    component: PostView,
    meta: {
      showSidebar: true,
      requiresAuth: true,
    },
  },

  {
    path: 'compose/voice/:id',
    name: 'compose-audio',
    component: ComposeAudio,
    meta: {
      showSidebar: false,
      requiresAuth: true,
    },
  },

  {
    path: 'compose/:type/:id',
    name: 'compose',
    component: Compose,
    meta: {
      showSidebar: false,
      requiresAuth: true,
    },
  },

  {
    path: 'campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: 'campaign/:id',
    component: Campaign,
    name: 'campaign',
    children: [
      {
        path: 'posts',
        name: 'campaign.posts',
        component: ViewCampaignPosts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'stats',
        name: 'campaign.stats',
        component: ViewCampaignPosts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'logs',
        name: 'campaign.logs',
        component: ViewCampaignPosts,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'draft',
    name: 'draft',
    component: Draft,
    redirect: { name: 'draft.overview' },
    children: [
      {
        path: 'overview',
        name: 'draft.overview',
        component: DraftOverview,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id/edit',
        name: 'draft.edit',
        component: DraftEdit,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: ':id/share',
        name: 'draft.share',
        component: DraftShare,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: 'planner',
    name: 'planner',
    component: Planner,
    redirect: { name: 'planner.calendar' },
    children: [
      {
        path: 'calendar',
        name: 'planner.calendar',
        component: PlannerCalender,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'list',
        name: 'planner.list',
        component: PlannerList,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: 'settings',
    name: 'brand.settings',
    component: Settings,
    redirect: { name: 'settings.brand' },
    children: [
      {
        path: 'brand',
        name: 'settings.brand',
        component: BrandSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'team',
        name: 'settings.team',
        component: TeamSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'social',
        name: 'settings.social',
        component: SocialAccountsSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'audio',
        name: 'settings.audio',
        component: AudioAccountsSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'blogs',
        name: 'settings.blogs',
        component: BlogAccountsSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'integrations',
        name: 'settings.integrations',
        component: IntegrationsSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
      {
        path: 'miscellaneous',
        name: 'settings.misc',
        component: MiscellaneousSetting,
        meta: {
          showSidebar: false,
          requiresAuth: true,
        },
      },
    ],
  },
];
