import gql from 'graphql-tag';
import {
  USER_FIELDS_FRAGMENT,
  POST_FIELDS_FRAGMENT,
  ARTICLE_FIELDS_FRAGMENT,
  BUSINESS_FIELDS_FRAGMENT,
  WORKSPACE_FIELDS_FRAGMENT,
  UTM_PRESET_FIELDS_FRAGMENT,
  AUTOMATION_FIELDS_FRAGMENT,
  PAYMENT_METHOD_FIELDS_FRAGMENT,
  HASHTAG_PRESET_FIELDS_FRAGMENT,
  PUBLISH_ACCOUNT_FIELDS_FRAGMENT,
  DESIGN_TEMPLATE_FIELDS_FRAGMENT,
  DESIGN_TEMPLATE_CATEGORY_FIELDS_FRAGMENT,
  POST_TASK_FIELDS_FRAGMENT,
  POST_COMMENT_FIELDS_FRAGMENT,
  POST_ACTIVITY_FIELDS_FRAGMENT,
  FOLDER_FIELDS_FRAGMENT,
  LEAD_FIELDS_FRAGMENT,
  LEAD_FINDER_FIELDS_FRAGMENT,
} from '~/graphql/fragments';


// export const GET_TEMPLATES_QUERY = gql`
//   query GetTemplates {
//     templates {
//       id
//       name
//       preview  // Assuming 'preview' is the field that contains the image URL
//     }
//   }
// `;

export const LIST_IMAGES_QUERY = gql`
  query ListImages {
    listImages {
      filename
      url
    }
  }
`;
export const FETCH_SSO_LINKS_QUERY = gql`
  query FetchSsoLinksQuery {
    fetchSsoLinks {
      facebook
      twitter
    }
  }
`;

export const USER_DETAILS_QUERY = gql`
  query UserDetailsQuery {
    userDetails {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const DASHBOARD_ARTICLES_QUERY = gql`
  query DashboardArticlesQuery($limit: Int) {
    articleSearch(limit: $limit) {
      articles {
        ...ArticleFields
      }
    }
  }
  ${ARTICLE_FIELDS_FRAGMENT}
`;

export const FETCH_DFY_WEBSITES_QUERY = gql`
  query {
    fetchDfyWebsites {
      id
      sub_domain
      name
      template_cover
    }
  }
`;
export const DASHBOARD_QUOTES_QUERY = gql`
  query DashboardQuery {
    quoteSearch {
      quotes {
        id
        author
        body
        upVotes
        downVotes
      }
    }
  }
`;

export const QUOTE_TYPE_AHEAD_QUERY = gql`
  query QuoteTypeAheadQuery {
    quoteTypeAhead {
      authors {
        name
        slug
        count
      }
      tags {
        name
        slug
        count
      }
    }
  }
`;

export const DASHBOARD_CAMPAIGNS_QUERY = gql`
  query DashboardCampaigsQuery($workspace: Int!, $campaignLimit: Int) {
    getCampaigns(workspace: $workspace, limit: $campaignLimit) {
      campaigns {
        id
        title
        status
        createdAt
        createdAtIso
        isActive
        postCount
      }
    }
  }
`;

export const DASHBOARD_POSTS_QUERY = gql`
  query DashboardPostsQuery($workspace: Int!, $postsLimit: Int) {
    singlePosts(workspace: $workspace, limit: $postsLimit) {
      posts {
        id
        createdAt
        status
        type
        masterContent
        accountPostContents {
          accountId
          content
          attachments {
            id
            url
          }
        }
        publishAccounts {
          id
          imageUrl
          name
        }
      }
    }
  }
`;

export const FACEBOOK_PAGES_GROUPS_QUERY = gql`
  query FacebookAccountsQuery($id: Int!, $workspace: Int!) {
    facebookAccounts(id: $id, workspace: $workspace) {
      pages {
        id
        name
        imageUrl
        connected
      }
      groups {
        id
        name
        imageUrl
        connected
      }
    }
  }
`;

export const FACEBOOK_PAGES_QUERY = gql`
  query FacebookPagesQuery($workspace: Int!) {
    facebookPages(workspace: $workspace) {
      id
      name
      imageUrl
      connected
    }
  }
`;

export const INSTAGRAM_ACCOUNTS_QUERY = gql`
  query InstagramAccountsQuery($id: Int!, $workspace: Int!) {
    instagramAccounts(id: $id, workspace: $workspace) {
      id
      name
      username
      imageUrl
      connected
    }
  }
`;

export const PUBLISH_ACCOUNTS_QUERY = gql`
  query PublishAccountsQuery($workspace: Int!, $type: String) {
    publishAccounts(workspace: $workspace, type: $type) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const LINKEDIN_ORGS_QUERY = gql`
  query LinkedinOrgsQuery($id: Int!, $workspace: Int!) {
    linkedinOrganisations(id: $id, workspace: $workspace) {
      id
      name
      imageUrl
      connected
    }
  }
`;

export const PINTEREST_BOARDS_QUERY = gql`
  query PinterestBoardsQuery($id: Int!, $workspace: Int!) {
    pinterestBoards(id: $id, workspace: $workspace) {
      id
      name
      imageUrl
      connected
    }
  }
`;

export const MEDIUM_PUBLICATIONS_QUERY = gql`
  query MediumPublicationQuery($id: Int!, $workspace: Int!) {
    mediumPublications(id: $id, workspace: $workspace) {
      id
      name
      imageUrl
      connected
    }
  }
`;

export const TUMBLR_BLOGS_QUERY = gql`
  query TumblrBlogsQuery($workspace: Int!, $id: Int!) {
    tumblrBlogs(workspace: $workspace, id: $id) {
      id
      name
      imageUrl
      connected
      profile
    }
  }
`;

export const CURATED_TOPICS_QUERY = gql`
  query CuratedTopicsQuery {
    curatedTopics {
      id
      title
      label
      photo
    }
  }
`;

export const GIF_SEARCH_QUERY = gql`
  query GifSearchQuery($keywords: String, $offset: Int, $limit: Int) {
    gifSearch(keywords: $keywords, offset: $offset, limit: $limit) {
      gifs {
        id
        title
        thumb
        imageUrl
      }
      total
      hasMore
    }
  }
`;

export const MEME_SEARCH_QUERY = gql`
  query MemeSearchQuery($query: String, $sort: String, $page: Int, $limit: Int) {
    memeSearch(query: $query, sort: $sort, page: $page, limit: $limit) {
      memes {
        id
        url
        type
        title
        isAlbum
        images {
          id
          type
          url
        }
        views
        ups
        downs
        imagesCount
        commentCount
      }
      total
      hasMore
    }
  }
`;

export const QUOTE_SEARCH_QUERY = gql`
  query QuoteSearchQuery($keywords: String, $page: Int) {
    quoteSearch(keywords: $keywords, page: $page) {
      quotes {
        id
        author
        body
      }
      total
      hasMore
    }
  }
`;

export const ARTICLE_SEARCH_QUERY = gql`
  query ArticleSearchQuery(
    $keywords: [String]
    $excludeKeywords: [String]
    $region: String
    $language: String
    $postAge: String
    $nextOffset: Int
    $limit: Int
    $sort: String
    $fromAfterDate: String
  ) {
    articleSearch(
      keywords: $keywords
      excludeKeywords: $excludeKeywords
      region: $region
      language: $language
      postAge: $postAge
      nextOffset: $nextOffset
      limit: $limit
      sort: $sort
      fromAfterDate: $fromAfterDate
    ) {
      articles {
        ...ArticleFields
      }
      nextOffset
      total
      hasMore
    }
  }
  ${ARTICLE_FIELDS_FRAGMENT}
`;

export const ARTICLE_SEARCH_BY_TOPIC_QUERY = gql`
  query ArticleSearchQuery(
    $topic: CuratedTopicInput!
    $region: String
    $language: String
    $postAge: String
    $nextOffset: Int
    $limit: Int
    $sort: String
    $fromAfterDate: String
  ) {
    articleSearchByTopic(
      topic: $topic
      region: $region
      language: $language
      postAge: $postAge
      nextOffset: $nextOffset
      limit: $limit
      sort: $sort
      fromAfterDate: $fromAfterDate
    ) {
      articles {
        ...ArticleFields
      }
      nextOffset
      total
      hasMore
    }
  }
  ${ARTICLE_FIELDS_FRAGMENT}
`;

export const GET_ARTICLE_QUERY = gql`
  query GetArticleQuery($id: String!) {
    getArticle(id: $id)
  }
`;

export const ALL_CAMPAIGNS_QUERY = gql`
  query CampaignsQuery($workspace: Int!, $offset: Int, $limit: Int) {
    getCampaigns(workspace: $workspace, offset: $offset, limit: $limit) {
      campaigns {
        id
        title
        status
        createdAt
        createdAtIso
        isActive
        postCount
      }
      total
      hasMore
    }
  }
`;

export const FETCH_CAMPAIGN_QUERY = gql`
  query FetchCampaignQuery($workspace: Int!, $id: Int) {
    campaign: fetchCampaign(workspace: $workspace, id: $id) {
      id
      title
      isActive
    }
  }
`;

export const CONTENT_CATEGORIES_QUERY = gql`
  query ContentCategoriesQuery {
    contentCategories {
      id
      title
    }
  }
`;

export const EDITOR_CONTENTS_QUERY = gql`
  query EditorContentsQuery($workspace: Int!, $limit: Int) {
    editorContents(workspace: $workspace, limit: $limit) {
      contents {
        id
        screenshot
        content
        excerpt
        createdAt
        updatedAt
        wordCount
        readTime
      }
      total
      hasMore
    }
  }
`;

export const FETCH_EDITOR_CONTENT_BY_ID_QUERY = gql`
  query EditorContentByIdQuery($workspace: Int!, $id: Int!) {
    editorContentById(workspace: $workspace, id: $id) {
      id
      content
      screenshot(size: "lg")
    }
  }
`;

export const BANNER_DESIGNS_QUERY = gql`
  query BannerDesignsQuery($workspace: Int!, $limit: Int) {
    bannerDesigns(workspace: $workspace, limit: $limit) {
      designs {
        id
        imageUrl
        canvasData
        thumb: imageUrl(size: "sm")
      }
      total
      hasMore
    }
  }
`;

export const FETCH_BANNER_DESIGN_BY_ID_QUERY = gql`
  query BannerDesignByIdQuery($workspace: Int!, $id: Int!) {
    bannerDesignById(workspace: $workspace, id: $id) {
      id
      imageUrl
      canvasData
      thumb: imageUrl(size: "sm")
    }
  }
`;

export const DESIGN_TEMPLATE_CATEGORIES_QUERY = gql`
  query DesignTemplateCategoriesQuery($offset: Int, $limit: Int) {
    designTemplatesCategories(offset: $offset, limit: $limit) {
      categories {
        ...DesignTemplateCategoryFields
      }
      total
      hasMore
    }
  }
  ${DESIGN_TEMPLATE_CATEGORY_FIELDS_FRAGMENT}
`;

export const DESIGN_TEMPLATES_QUERY = gql`
  query DesignTemplatesQuery($category: Int, $offset: Int, $limit: Int) {
    designTemplates(category: $category, offset: $offset, limit: $limit) {
      templates {
        ...DesignTemplateFields
      }
      total
      hasMore
    }
  }
  ${DESIGN_TEMPLATE_FIELDS_FRAGMENT}
`;

export const LINK_PREVIEW_QUERY = gql`
  query LinkPreviewQuery($url: String!) {
    linkPreview(url: $url) {
      title
      description
      images
      website
    }
  }
`;

export const UTM_PRESETS_QUERY = gql`
  query UtmPresetsQuery($workspace: Int!) {
    utmPresets(workspace: $workspace) {
      ...UtmPresetFields
    }
  }
  ${UTM_PRESET_FIELDS_FRAGMENT}
`;

export const HASHTAG_PRESETS_QUERY = gql`
  query HashtagPresetsQuery($workspace: Int!) {
    hashtagPresets(workspace: $workspace) {
      ...HashtagPresetFields
    }
  }
  ${HASHTAG_PRESET_FIELDS_FRAGMENT}
`;

export const AUTOMATIONS_QUERY = gql`
  query AutomationsQuery($workspace: Int!, $type: String!, $recipe: String!, $search: String, $accountIds: [Int]) {
    automations(workspace: $workspace, type: $type, recipe: $recipe, search: $search, accountIds: $accountIds) {
      ...AutomationFields
    }
  }
  ${AUTOMATION_FIELDS_FRAGMENT}
`;

export const FETCH_AUTOMATION_QUERY = gql`
  query FetchAutomationQuery($workspace: Int!, $id: Int!) {
    automation(workspace: $workspace, id: $id) {
      id
      name
      busy
      nextRefillTimeFormatted
      aiAgentName
      aiAgentImage
      selectedAccounts {
        accountId
        platform
        publishVariant
        publishAuthorId
        publishCategoryIds
        publishStatus
      }
      posts {
        ...PostFields
      }
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const FETCH_POST_QUERY = gql`
  query FetchPost($workspace: Int!, $id: Int!) {
    fetchPost(workspace: $workspace, id: $id) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const FETCH_WORDPRESS_AUTHORS_AND_CATEGORIES_QUERY = gql`
  query FetchWordpressAuthorsAndCategoriesQuery($id: Int!) {
    wordpressAuthorsAndCategories(id: $id) {
      authors {
        name
        id
      }
      categories {
        name
        id
      }
    }
  }
`;

export const POSTS_QUERY = gql`
  query PostsQuery($workspace: Int!, $offset: Int, $limit: Int, $status: String) {
    singlePosts(workspace: $workspace, offset: $offset, limit: $limit, status: $status) {
      posts {
        ...PostFields
      }
      total
      hasMore
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const VIDEO_SEARCH_QUERY = gql`
  query VideoSearchQuery(
    $keywords: [String]
    $page: Int
    $limit: Int
    $nextPageToken: String
    $language: String
    $sort: String
    $source: String
    $dateRange: String
    $filters: [AdvancedSearchFilterInput]
  ) {
    videoSearch(
      keywords: $keywords
      page: $page
      limit: $limit
      nextPageToken: $nextPageToken
      language: $language
      sort: $sort
      source: $source
      dateRange: $dateRange
      filters: $filters
    ) {
      videos {
        id
        description
        thumb
        title
        url
        publishDate
        embedHtml
        commentCount
        likeCount
        viewCount
      }
      total
      nextPageToken
      page
      hasMore
    }
  }
`;

export const YOUTUBE_VIDEO_DETAILS_QUERY = gql`
  query YoutubeVideoDetailsQuery($id: String!) {
    youtubeVideoDetails(id: $id) {
      id
      description
      thumb
      title
      url
      publishDate
      embedHtml
      commentCount
      likeCount
      viewCount
    }
  }
`;

export const USER_UPLOADS_QUERY = gql`
  query UserUploadsQuery($workspace: Int!, $type: String, $limit: Int) {
    userUploads(workspace: $workspace, type: $type, limit: $limit) {
      uploads {
        id
        thumb
        url
        type
        status
      }
      total
      hasMore
    }
  }
`;

export const USER_NOTIFICATIONS_QUERY = gql`
  query UserNotificationQuery {
    userNotifications {
      unreadCount
      notifications {
        id
        title
        description
        entity
        entityId
        isRead
        type
        createdAt
      }
    }
  }
`;

export const CAMPAIGN_POSTS_QUERY = gql`
  query CampaignPostsQuery($workspace: Int!, $campaignId: Int!, $offset: Int, $limit: Int) {
    campaignPosts(workspace: $workspace, campaignId: $campaignId, offset: $offset, limit: $limit) {
      days {
        day
        formatedDate
        posts {
          ...PostFields
        }
      }
      total
      hasMore
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const STOCK_IMAGE_SEARCH_QUERY = gql`
  query StockImageSearchQuery($keywords: String, $limit: Int, $offset: Int) {
    images: stockImageSearch(keywords: $keywords, limit: $limit, offset: $offset) {
      contents {
        id
        thumb
        imageUrl
        authorName
        authorUrl
      }
      total
      hasMore
    }
  }
`;

export const SETUP_INTENT_QUERY = gql`
  query SetupIntentQuery {
    setupIntent {
      id
      clientSecret
    }
  }
`;

export const PLANNER_POSTS_QUERY = gql`
  query PlannerPostsQuery($workspace: Int!, $startDate: String!, $endDate: String!) {
    plannerPosts(workspace: $workspace, startDate: $startDate, endDate: $endDate) {
      id
      utcScheduleDate
      utcScheduleTime
      status
      type
      masterContent
      campaignId
      campaign {
        id
        title
      }
      accountPostContents {
        accountId
        content
        attachments {
          id
          url
        }
      }
      publishAccounts {
        id
        imageUrl
        name
      }
    }
  }
`;

export const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethodsQuery {
    paymentMethods {
      ...PaymentMethodFields
    }
  }
  ${PAYMENT_METHOD_FIELDS_FRAGMENT}
`;

export const LOGO_SUGGESTIONS_QUERY = gql`
  query LogoSuggestionsQuery($name: String!) {
    logoSuggestions(name: $name) {
      name
      domain
      logo
    }
  }
`;

export const FETCH_BUSINESS_QUERY = gql`
  query FetchBusinessQuery($limit: Int) {
    fetchBusiness(limit: $limit) {
      ...BusinessFields
    }
  }
  ${BUSINESS_FIELDS_FRAGMENT}
`;

export const FETCH_WORKSPACES_QUERY = gql`
  query FetchWorkspacesQuery($limit: Int) {
    fetchWorkspaces(limit: $limit) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const FETCH_WORKSPACE_BY_SLUG_QUERY = gql`
  query FetchWorkspaceQuery($slug: String!) {
    fetchWorkspaceBySlug(slug: $slug) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const YOUZIGN_DESIGNS_QUERY = gql`
  query YouzignDesignsQuery($workspace: Int!, $page: Int, $limit: Int) {
    youzignDesigns(workspace: $workspace, page: $page, limit: $limit) {
      id
      imageUrl
      thumb
    }
  }
`;

export const FETCH_RESELLER_USERS_QUERY = gql`
  query FetchResellerUsersQuery($limit: Int) {
    fetchResellerUsers(limit: $limit) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const RESELLER_WEBHOOK_URLS_QUERY = gql`
  query ResellerResellerUrlsQuery {
    resellerWebhookUrls {
      lifetime
      monthly
      platform
    }
  }
`;

export const POST_TASKS_WITH_ANALYTICS_QUERY = gql`
  query postTasksWithAnalyticsQuery($postId: Int!, $limit: Int, $offset: Int) {
    postTasksWithAnalytics(postId: $postId, limit: $limit, offset: $offset) {
      postTasks {
        ...PostTaskFields
      }
      analytics {
        total
        complete
      }
    }
  }
  ${POST_TASK_FIELDS_FRAGMENT}
`;

export const POST_COMMENTS_WITH_ANALYTICS_QUERY = gql`
  query postCommentsWithAnalyticsQuery($postId: Int!, $limit: Int, $offset: Int) {
    postCommentsWithAnalytics(postId: $postId, limit: $limit, offset: $offset) {
      postComments {
        ...PostCommentFields
      }
      analytics {
        total
      }
    }
  }
  ${POST_COMMENT_FIELDS_FRAGMENT}
`;

export const POST_COMMENTS_WITH_INSIGHTS_AND_ANALYTICS_QUERY = gql`
  query postCommentsWithAnalyticsQuery($postId: Int!, $limit: Int, $offset: Int) {
    postCommentsWithAnalytics(postId: $postId, limit: $limit, offset: $offset) {
      postComments {
        ...PostCommentFields
        hasRead
        isCurrentUser
      }
      analytics {
        total
      }
    }
  }
  ${POST_COMMENT_FIELDS_FRAGMENT}
`;

export const LAST_UNREAD_POST_COMMENTS_QUERY = gql`
  query lastUnreadPostCommentsQuery($limit: Int, $offset: Int) {
    lastUnreadPostComments(limit: $limit, offset: $offset) {
      name
      message
      workspace_id
      workspace_name
      workspace_logo
      workspace_slug
      post_id
      post_comment_id
      like_id
      favourite_id
      is_read
      date
    }
  }
`;

export const POST_ACTIVITIES_WITH_ANALYTICS_QUERY = gql`
  query postActivitiesWithAnalyticsQuery($postId: Int!, $limit: Int, $offset: Int) {
    postActivitiesWithAnalytics(postId: $postId, limit: $limit, offset: $offset) {
      postActivities {
        ...PostActivityFields
      }
      analytics {
        total
      }
    }
  }
  ${POST_ACTIVITY_FIELDS_FRAGMENT}
`;

export const REPORT_QUERY = gql`
  query ReportQuery(
    $workspace: Int!
    $name: String!
    $accountIds: [Int]
    $direction: String
    $lastId: Int
    $limit: Int
  ) {
    reports(
      workspace: $workspace
      name: $name
      account_ids: $accountIds
      direction: $direction
      last_id: $lastId
      limit: $limit
    ) {
      ... on ReportOverviewSummary {
        chart {
          labels
          comments
          reactions
          reposts
          posts
        }
        total {
          comments
          posts
          shares
          engagements
          post_per_day
          engagement_per_day
        }
      }

      ... on ReportOverviewTopPostsByEngagement {
        post_contents {
          id
          title
          content
          platform
          link
          publishStatus
          status
          createdAt
          image
          reports {
            comments
            likes
            engagements
            shares
          }
          user {
            last_name
            first_name
            avatar
          }
        }
      }

      ... on ReportOverviewSocialPerformance {
        accounts {
          id
          name
          type
          platform
          reports {
            likes
            shares
            comments
          }
        }
      }

      ... on ReportOverviewPlatformEngagement {
        platforms {
          platform
          reports {
            posts
            likes
            shares
            comments
          }
        }
      }
    }
  }
`;

export const POST_CONTENTS_WITH_REPORT_QUERY = gql`
  query postContentsWithReportQuery($workspace: Int!, $direction: String, $lastId: Int, $limit: Int) {
    postContentsWithReport(workspace: $workspace, direction: $direction, last_id: $lastId, limit: $limit) {
      postId
      id
      title
      content
      platform
      link
      publishStatus
      status
      createdAt
      isApproved
      image
      reports {
        comments
        likes
        engagements
        shares
      }
      user {
        last_name
        first_name
        avatar
      }
    }
  }
`;

export const FETCH_FOLDER_QUERY = gql`
  query FetchFolder($purpose: String, $limit: Int, $offset: Int) {
    fetchFolder(purpose: $purpose, limit: $limit, offset: $offset) {
      ...FolderFields
    }
  }
  ${FOLDER_FIELDS_FRAGMENT}
`;

export const FETCH_LEAD_QUERY = gql`
  query FetchLead($folderId: Int, $limit: Int, $offset: Int) {
    fetchLead(folder_id: $folderId, limit: $limit, offset: $offset) {
      ...LeadFields
    }
  }
  ${LEAD_FIELDS_FRAGMENT}
`;

export const LEAD_FINDER_QUERY = gql`
  query LeadFinderQuery($location: String!, $latitude: Float!, $longitude: Float! $keyword: String, $radius: Int) {
    leadFinderQuery(location: $location, latitude: $latitude, longitude: $longitude, keyword: $keyword, radius: $radius) {
      ...LeadFinderFields
    }
  }
  ${LEAD_FINDER_FIELDS_FRAGMENT}
`;
