import gql from 'graphql-tag';

export const TEAM_MEMBER_FIELDS_FRAGMENT = gql`
  fragment TeamMemberFields on TeamMember {
    id
    user {
      id
      photoUrl: photoUrl(size: 150)
    }
    firstName
    lastName
    fullName
    email
    role
    type
    photo
    status
  }
`;

export const WORKSPACE_FIELDS_FRAGMENT = gql`
  fragment WorkspaceFields on Workspace {
    id
    name
    slug
    timezone
    logo
    notes
    default
    createdAt
    utmStatus
    linkShortener
    bitlyConnected
    bitlyUsername
    bitlyDomain
    user {
      id
      photoUrl: photoUrl(size: 150)
    }
    members {
      ...TeamMemberFields
    }
    postsCount
    campaignsCount
    automationsCount
    socialAccountsCount
    blogAccountsCount
    limits {
      workspaces
      socialAccounts
      blogs
      members
      automations
      topics
    }
    permissions
    limitsUsage {
      workspaces
      socialAccounts
      blogs
      members
      automations
      topics
    }
    youzignProfile {
      name
      bio
      photo
    }
  }
  ${TEAM_MEMBER_FIELDS_FRAGMENT}
`;

export const BUSINESS_FIELDS_FRAGMENT = gql`
  fragment BusinessFields on Business {
    id
    name
    address
    phone
    slug
    photoUrl
    user {
      id
      photoUrl: photoUrl(size: 150)
    }
    workspace {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const USER_PLAN_FIELDS = gql`
  fragment PlanFields on UserPlan {
    id
    name
    interval
    variant
    slug
    limits {
      workspaces
      socialAccounts
      blogs
      members
      automations
      topics
    }
  }
`;

export const USER_SUBSCRIPTION_FIELDS_FRAGMENT = gql`
  fragment UserSubscriptionFields on UserSubscription {
    id
    startsAt
    endsAt
    plan {
      ...PlanFields
    }
    addons {
      ...PlanFields
    }
    limitsUsage {
      workspaces
      socialAccounts
      blogs
      members
      automations
      topics
    }
  }
  ${USER_PLAN_FIELDS}
`;

export const USER_FIELDS_FRAGMENT = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    status
    photoUrl
    photoUrl_50: photoUrl(size: 50)
    photoUrl_150: photoUrl(size: 150)
    subscribed
    hasVerifiedEmail
    onGracePeriod
    subscription {
      ...UserSubscriptionFields
    }
    permissions
    options {
      onboarding {
        dashboard
        socialComposer
        blogComposer
        imageDesigner
        homepage
      }
    }
  }
  ${USER_SUBSCRIPTION_FIELDS_FRAGMENT}
`;

export const AUTOMATION_FIELDS_FRAGMENT = gql`
  fragment AutomationFields on Automation {
    id
    name
    publishCount
    busy
    publishAccounts {
      id
      imageUrl
    }
    selectedAccounts {
      accountId
      platform
      publishVariant
      publishAuthorId
      publishCategoryIds
      publishStatus
    }
    nextRefillTimeFormatted
    status

    source
    hashtagPresetId
    recurringValue
    recurringUnit
    scheduleType
    startDate
    endDate
    aiPrompt
    aiAgentName
    aiAgentImage
    reviewPosts
    maxPostCount
    prefetchDaysLimit
    filters {
      keywords
      includeKeywords
      excludeKeywords
      excludeDomains
      limitDomains
      language
      postAge
      fromAfterDate
    }
  }
`;

export const DESIGN_TEMPLATE_FIELDS_FRAGMENT = gql`
  fragment DesignTemplateFields on DesignTemplate {
    id
    thumb
    canvasData
  }
`;

export const DESIGN_TEMPLATE_CATEGORY_FIELDS_FRAGMENT = gql`
  fragment DesignTemplateCategoryFields on DesignTemplateCategory {
    id
    title
    description
    templates(limit: 2) {
      ...DesignTemplateFields
    }
  }
  ${DESIGN_TEMPLATE_FIELDS_FRAGMENT}
`;

export const HASHTAG_PRESET_FIELDS_FRAGMENT = gql`
  fragment HashtagPresetFields on HashtagPreset {
    id
    name
    items
    insertCount
    insertType
  }
`;

export const UTM_PRESET_FIELDS_FRAGMENT = gql`
  fragment UtmPresetFields on UtmPreset {
    id
    name
    medium
    source
    autoAddSocialChannel
    default
  }
`;

export const PUBLISH_ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment PublishAccountFields on PublishAccount {
    id
    name
    type {
      platform
      accountType
      displayName
      rules {
        id
        title
        content
        attachments
      }
      isPublishable
      platformType
      publishStatuses {
        value
        text
      }
    }
    imageUrl
    expiresIn
    expired
  }
`;

export const POST_ATTACHMENT_FIELDS_FRAGMENT = gql`
  fragment PostAttachmentFields on PostAttachment {
    id
    type
    url
    thumb
    platform
  }
`;

export const POST_CONTENT_FIELDS_FRAGMENT = gql`
  fragment PostContentFields on PostContent {
    id
    title
    content
    createdAt
    status
    statusMessage
    accountId
    liveLink
    attachments {
      ...PostAttachmentFields
    }
    postLink
    publishAccount {
      ...PublishAccountFields
    }
    platform
    publishVariant
    publishAuthorId
    publishCategoryIds
    publishStatus
  }
  ${POST_ATTACHMENT_FIELDS_FRAGMENT}
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const POST_FIELDS_FRAGMENT = gql`
  fragment PostFields on Post {
    id
    scheduleDate
    scheduleTimezone
    utcScheduleTime
    status
    title
    masterContent
    postLink
    featuredImageUrl
    voiceId
    audioUrl
    createdAt
    createDate
    type
    parentType
    user {
      id
      firstName
      lastName
      photoUrl_50: photoUrl(size: 50)
    }
    attachments {
      ...PostAttachmentFields
    }
    accountPostContents {
      ...PostContentFields
    }
    publishAccounts {
      ...PublishAccountFields
    }
    processStatus
    isApproved
  }
  ${POST_ATTACHMENT_FIELDS_FRAGMENT}
  ${POST_CONTENT_FIELDS_FRAGMENT}
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const PAYMENT_METHOD_FIELDS_FRAGMENT = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    brand
    last4
    expMonth
    expYear
    default
  }
`;

export const ARTICLE_FIELDS_FRAGMENT = gql`
  fragment ArticleFields on Article {
    id
    author
    twitterUsername
    categories
    topics
    image
    title
    url
    domain
    score
    engagement
    facebookShares
    twitterShares
    pinterestShares
    linkedinShares
    redditShares
    sentiment
    readTime
    publishDate
    discoverDate
  }
`;

export const POST_TASK_FIELDS_FRAGMENT = gql`
  fragment PostTaskFields on PostTask {
    id
    text
    isComplete
    canChange
    user {
      id
      firstName
      lastName
    }
  }
`;

export const POST_COMMENT_FIELDS_FRAGMENT = gql`
  fragment PostCommentFields on PostComment {
    id
    message
    date
    user {
      id
      firstName
      lastName
    }
  }
`;

export const POST_ACTIVITY_FIELDS_FRAGMENT = gql`
  fragment PostActivityFields on PostActivity {
    id
    type
    action
    message
    date
    user {
      id
      firstName
      lastName
    }
  }
`;

export const FOLDER_FIELDS_FRAGMENT = gql`
  fragment FolderFields on Folder {
    id
    name
    purpose
    createdAt
  }
`;

export const LEAD_FIELDS_FRAGMENT = gql`
  fragment LeadFields on Lead {
    id
    firstName
    lastName
    email
    phoneCode
    phoneNumber
    address
    title
    company
    domain
    createdAt
  }
`;

export const LEAD_FINDER_FIELDS_FRAGMENT = gql`
  fragment LeadFinderFields on LeadFinder {
    name
    rating
    address
    phone
    website
  }
`;
