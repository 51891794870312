<template>
  <div class="finder-menu" :class="{ 'finder-menu--active': showFinderMenu }">
    <div id="finder-dropDown" class="finder-menu__button" @click="showFinderMenu = !showFinderMenu">
      <span class="finder-menu__btn text-sm">
        <div class="text-sm">
          <b-avatar size="2rem" class="mr-2" :src="currentWorkspace.logo" />
          <span class="mr-auto text-capitalize">
            {{ currentWorkspace.name | truncate(8, '...') }}
          </span>
        </div>
        <span>
          <svg width="20" height="20" viewBox="0 0 16 16" fill="none" class="!text-white caret"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 6.5L8 11L12.5 6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </span>
      </span>
    </div>

    <div class="finder-menu__menu-box">
      <div class="finder-menu__menu-box-title">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <img src="@/assets/icons/new/search.svg" alt="search icon" />
            </span>
          </div>
          <input type="text" class="form-control" placeholder="Search a brand" aria-label="search"
            aria-describedby="search-addon1" />
        </div>
      </div>
      <div class="">
        <simplebar class="finder__result px-3" v-if="fetchWorkspaces.length">
          <div class="d-flex align-items-center mb-3 cursor-pointer" @click="openWorkspace(currentWorkspace)">
            <b-avatar class="mr-2" size="2rem" :src="currentWorkspace.logo"></b-avatar>
            <span class="mr-auto f-12 text-capitalize" v-b-tooltip.hover :title="currentWorkspace.name">
              {{ currentWorkspace.name | truncate(10, '...') }}
            </span>
            <div class="d-flex align-items-center">
              <input type="checkbox" id="activeWorkspace" class="remember_input"
                :checked="currentWorkspace ? true : false" />
              <label style="margin-bottom: 0;" for="activeWorkspace"></label>
            </div>
          </div>
          <div class="mb-3 cursor-pointer" v-for="(workspace, i) in fetchWorkspaces" :key="i"
            @click="openWorkspace(workspace)">
            <div class="d-flex align-items-center " v-if="workspace.slug !== currentWorkspace.slug">
              <b-avatar class="mr-2" size="2rem" :src="workspace.logo"></b-avatar>
              <span class="mr-auto f-12 text-capitalize" v-b-tooltip.hover :title="workspace.name">
                {{ workspace.name | truncate(10, '...') }}
              </span>
              <div>
                <input type="checkbox" id="activeWorkspace" class="remember_input" />
                <label for="activeWorkspace"></label>
              </div>
            </div>
          </div>
        </simplebar>
      </div>
      <div class="finder-menu__footer mx-auto">
        <router-link :to="{ name: 'workspaces.create' }" class="ft-btn">
          <svg-icon name="plus" size="sm" />
          Add New
        </router-link>
        <router-link :to="{ name: 'workspaces' }" class="ft-btn">
          <svg-icon name="list" size="sm" />
          View All
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import { BAvatar } from 'bootstrap-vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
export default {
  components: { simplebar, BAvatar },
  data() {
    return {
      fetchWorkspaces: [],
      showFinderMenu: false,
      activeItem: 1,
    };
  },

  apollo: {
    fetchWorkspaces: {
      query: FETCH_WORKSPACES_QUERY,
    },
  },

  computed: {
    ...mapState({
      currentWorkspace: state => state.workspace.current,
    }),
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showFinderMenu = false;
      }
    });
  },

  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  methods: {
    openWorkspace(workspace) {
      this.$router.go({ name: 'dashboard', params: { workspace: workspace.slug } });
    },

    activeWorkspace(workspace) {
      this.currentWorkspace.slug === workspace.slug;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

$bg-color: #42c589;

.finder-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;
  font-size: 13px;

  &__button {
    background: rgba($bg-color, 1);
    // border: 1px solid rgba($bg-color, 1);
    color: black;
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    text-align: center;
    border-radius: 5px;
    // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &__btn {
    font-size: 13px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 145px;
  }

  &__menu-box {
    position: absolute;
    width: 100%;
    // min-width: 190px;
    // max-width: 190px;
    left: 0px;
    background-color: $bg-color;
    color: #000000;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2px);

    // margin-top: 5px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      // border-bottom: 12px solid $bg-color;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      // top: -24px;
      left: 45%;
    }

    &::after {
      content: '';
      background-color: transparent;
    }

    &-left {
      border-right: 1px solid #e0e2e9;
    }

    &-title {
      margin: 10px;

      .input-group {
        border: 1px solid #74c69d !important;
        color: $primary !important;
        gap: 0.4rem;
        border-radius: 10px !important;
        background-color: #d8f3dc !important;
      }

      .input-group-prepend {
        padding: 0 !important;
      }

      input,
      .input-group-prepend,
      .input-group-text {
        width: 1.4rem;
        padding: 0.5rem 0.3rem;
        // outline: 1px solid red;
        background-color: transparent;
        border: none;
        height: 100%;

        // outline: none;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          border: none;
          outline: none;
        }

        &::placeholder {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__menu {
    margin: 0;
    padding: 0 10px;
    list-style: none;
  }

  .finder__result {
    max-height: 150px;
    overflow-y: auto;

    .simplebar-scrollbar {
      width: 5px;
    }

    .simplebar-scrollbar:before {
      border-radius: 5px;
      background: rgba($primary, 0.7);
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      /* When hovered, remove all transitions from drag handle */
      opacity: 1;
      transition: opacity 0s linear;
    }

    .remember_input {
      display: none;
    }

    .remember_input+label:before {
      content: '\2714';
      border: 0.13em solid #52b788;
      border-radius: 50%;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: transparent;
      transition: 0.2s;
    }

    .remember_input+label:active:before {
      transform: scale(0);
    }

    .remember_input:checked+label:before {
      background-color: MediumSeaGreen;
      border-color: MediumSeaGreen;
      color: #fff;
    }

    &-input {
      position: relative;
      cursor: pointer;
      margin-top: 2px;
      width: 25px;
      height: 25px;
      border-radius: 20px;
      background-color: #d8f3dc;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      -webkit-appearance: none;

      .icon {
        display: none;
        margin-top: 5px;
        margin-left: 5px;
      }

      &--active {
        .icon {
          display: block;
        }
      }
    }
  }

  &__footer {
    width: 90%;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    margin-top: 10px;

    .ft-btn {
      background-color: #d8f3dc;
      color: #000000;
      padding: 10px 8px;
      width: 50%;
      text-decoration: none;
      font-size: 10px;
      font-weight: bold;

      &:first-of-type {
        border-radius: 5px 0 0 0;
        padding-left: 15px;
        margin-right: 2px;
      }

      &:last-of-type {
        border-radius: 0 5px 0 0;
        padding-right: 15px;
      }

      &:hover {
        background-color: $primary;
        color: #d8f3dc;
        text-decoration: none;
        // svg {
        //   fill: #d8f3dc;
        // }
      }
    }
  }
}

.finder-menu--active .finder-menu__menu-box {
  visibility: visible;
  opacity: 1;
  // margin-top: 15px;
}
</style>
