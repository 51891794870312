<template>
  <b-navbar :toggleable="false" fixed="top" type="dark" variant="default" class="Navbar "
    :class="{ navbar__bg: isScrollPassed }">
    <b-container :fluid="fluid" class="py-4">
      <div class="m-0 row workspace_header align-items-center justify-content-between">
        <div class="col">
          <div>
            <router-link :to="{ name: 'workspaces' }" class="  d-flex align-items-center">
              <div class="ml-2">
                <img src="@/assets/newentry/AISocialAgents-Dark.svg" width="130" alt="" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-md-7 h-100" v-if="!isUserClientOrApprover">
          <div class="header__data-info h-100">
            <div class="sa-info-nav h-100 d-flex justify-content-center align-items-center">
              <ol class="sa-info-nav-cont px-3 h-100">
                <!-- <li class="sa-info-nav-list">
                  <router-link class="list-link" :to="{ name: 'campaigns' }" active-class="active">
                    <svg-icon name="arrow-left" class="mr-1" size="md" />
                    <span class="title">Campaigns</span> 
                  </router-link>
                </li> -->
                <li class="sa-info-nav-list mr-2" v-if="isUserAdminInWorkspace()">
                  <router-link class="ai-avatar__campaign pr-3" :to="{ name: 'ai-automation.home' }"
                    active-class="active">
                    <div class="ai-avatar__automation mt-1">
                      <svg-icon name="automation" class="mr-2" size="md" />
                    </div>
                    <span style="white-space: nowrap;" class="title">AI Social Agent </span>
                  </router-link>
                </li>
                <li class="sa-info-nav-list">
                  <ComposeMenu />
                </li>
                <li class="sa-info-nav-list">
                  <ContentMenu />
                </li>

                <li class="sa-info-nav-list ">
                  <router-link class="list-link" :to="{ name: 'planner' }" active-class="active">
                    <svg-icon name="calendar-alt" size="md" />
                    <span class="title">Planner</span>
                  </router-link>
                </li>
                <!-- <li class="sa-info-nav-list">
                  <router-link class="list-link" :to="{ name: 'campaigns' }" active-class="active">
                    <svg-icon name="activity" class="mr-1 !size-4" />
                    <span class="title">Campaigns</span>
                  </router-link>
                </li> -->
                <li class="sa-info-nav-list" v-if="isUserAdminInWorkspace()">
                  <router-link class="list-link" :to="{ name: 'automation.home' }" active-class="active">
                    <svg-icon name="hierarchy" size="md" />
                    <span class="title">Recipe</span>
                  </router-link>
                </li>
                <!-- <li class="sa-info-nav-list" v-if="isUserAdminInWorkspace()">
                  <router-link class="list-link" :to="{ name: 'reports' }" active-class="active">
                    <svg-icon name="analytics" class="mr-1" size="md" />
                    <span class="title">Analysis</span>
                  </router-link>
                </li> -->

                <!-- <li class="sa-info-nav-list" v-if="isUserAdminInWorkspace()">
                  <router-link class="list-link" :to="{ name: 'video-composer' }" active-class="active">
                    <svg-icon name="resume" size="md" />
                    <span class="title" style="white-space: nowrap;">Ai Videos</span>
                  </router-link>
                </li> -->
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div style="gap: 22px;" class="user__data d-flex align-items-center justify-content-between">
            <div class="user__data-chat">
              <ChatMenu />
            </div>
            <div class="user__data-notification">
              <NotificationsMenu v-if="!isUserClientOrApprover" />
            </div>
            <div @click="showAnnouncement = !showAnnouncement" class="d-flex text-sm">
              <svg-icon name="mic-icon" class="mr-1 text-primary  cursor-pointer" size="md" />
              <p class="mb-0">Roadmap</p>
            </div>
            <div class="user__data-user">
              <BrandMainMenu :user="user" :authBusy="authBusy" />
              <!-- <UserMenu :user="user" :authBusy="authBusy" /> -->
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <transition name="fade">
      <AnnouncementMenu style="margin-top: 3rem; color: black; " v-if="showAnnouncement"
        @close="handleCloseAnnouncementMenu" />
    </transition>
  </b-navbar>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import ComposeMenu from './General/Menus/ComposeMenu.vue';
import ContentMenu from './General/Menus/ContentMenu.vue';
import UserMenu from './General/Menus/UserMenu.vue';
// import FinderMenu from './General/Menus/BrandMenu.vue';
import NotificationsMenu from './General/Menus/NotificationMenu.vue';
import AnnouncementMenu from './General/Menus/AnnouncementMenu.vue';
import ChatMenu from './General/Menus/ChatMenu';
// import NavbarChangelog from '~/components/General/NavbarChangelog';
import BrandMainMenu from './General/Menus/BrandMainMenu.vue';
import SvgIcon from './SvgIcon.vue';
const accessControl = createNamespacedHelpers('accessControl');

export default {
  name: 'Navbar',

  components: {
    ComposeMenu,
    ContentMenu,
    // FinderMenu,
    NotificationsMenu,
    AnnouncementMenu,
    BrandMainMenu,
    // UserMenu,
    // NavbarChangelog,
    SvgIcon,
    ChatMenu,
  },

  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeDropdownLink: null,
      scrollY: 0,
      showAnnouncement: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
      workspaces: state => state.workspace.workspaces,
      business: state => state.business.current,
      currentWorkspace: state => state.workspace.current,
    }),

    ...accessControl.mapGetters([
      'isUserSubscribed',
      'isUserClientOrApprover',
      'isUserAdminInWorkspace',
      'isUserClientInWorkspace',
    ]),

    computedActiveLink() {
      if (this.activeDropdownLink) {
        return this.activeDropdownLink;
      } else {
        return this.links[0];
      }
    },

    isScrollPassed() {
      return this.scrollY > 55;
    },
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    switchWorkspace(workspace) {
      const current = this.workspaces.find(w => w.slug === workspace.slug);

      if (current) {
        this.$store.dispatch('workspace/updateCurrent', current);
        this.$router.push({ params: { workspace: workspace.slug } });
        this.$refs.WorkspaceDropdown.hide();
      }
    },

    addWorkspace() {
      this.$router.push({ name: 'workspaces.create' });
    },

    setActiveDropdownLink(id) {
      this.activeDropdownLink = this.links.find(link => link.id === id);
    },
    handleCloseAnnouncementMenu() {
      this.showAnnouncement = false;
    },
    async logout() {
      // Log out the user.
      await this.$store.dispatch('auth/logout');

      // Redirect to login.
      this.$router.push({ name: 'signin' });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

// .sa-info-nav-list:nth-child(2):hover,
// .sa-info-nav-list:nth-child(3):hover {
//   background: $primary;
//   color: white !important;
//   text-decoration: none;
//   border-radius: 20px;
// }
.sa-info-nav {
  &-cont {
    list-style: none;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    border-radius: 0 0 20px 20px;
    // background-color: #95d5b2;
    background-color: $light;

    & li {
      height: auto;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0.6rem 0rem;
      font-size: 0.75rem !important;

      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &>*,
      span.compose-menu__btn {
        align-items: center;
        display: flex;
        flex-direction: row;
      }

      &:hover {
        // background: $primary;
        // color: white !important;
        // text-decoration: none;
        // border-radius: 20px;
      }
    }
  }
}

.Navbar {
  height: $navbar-height;
  margin-top: 0px;

  &.navbar__bg {
    // background: $light;
  }

  &.fixed-top {
    @media (max-width: 1310px) {
      position: sticky;
      width: 1310px;
    }
  }

  .workspace_header {
    color: #000000;
    width: 100%;
    height: $navbar-height;

    .list-link {
      color: $primary;
      padding: 0.6rem 1rem;
      border-radius: 20px;

      &:hover {
        background: $primary;
        color: white !important;
        text-decoration: none;
      }
    }

    .info__stat {
      width: 100%;
      padding: 0;
      margin: 0;

      &-cont {
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        border-radius: 0 0 30px 30px;
        background-color: #95d5b2;
        width: 100%;
        padding-bottom: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &-list {
        flex: 1;
        padding: 12px 0 6px 0;
        background-color: #95d5b2;
        // color: $primary;
        min-width: 60px;
        line-height: 10px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        position: relative;
        font-size: 1rem;

        &:first-of-type {
          border-radius: 0 0 0 30px;
        }

        &:last-of-type {
          border-radius: 0 0 30px 0;
        }

        .list-link {
          color: $primary;

          &:hover {
            color: #d8f3dc;
            text-decoration: none;
          }
        }

        &:hover,
        &:focus,
        &:active {
          .list-link {
            color: #d8f3dc;

            &:hover {
              color: #d8f3dc;
              text-decoration: none;
            }
          }

          background: $primary;
          color: #d8f3dc;
          text-decoration: none;
          border-radius: 0 0 10px 10px;
          padding-inline: 0.5rem;

          svg {
            fill: #d8f3dc !important;
          }

          .caret {
            fill: none;
            stroke: #d8f3dc;
          }
        }

        .title {
          font-weight: bold;
          font-size: 12px;
          text-align: left;
        }
      }
    }

    .user__data {
      &-chat {
        margin: 0;
      }

      &-alert {
        margin: 0;
        padding: 0 10px 0 0;
        line-height: 50px;
      }

      &-notification {
        // border-right: 2px solid #000000;
        // margin: 0 10px;
        // padding: 0 10px 0 0;
        line-height: 30x;
      }

      &-user {
        text-align: left;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

/* Set the starting opacity to 0 for entering */
.fade-enter {
  opacity: 0;
}

/* Set the opacity to 0 when leaving */
.fade-leave-to {
  opacity: 0;
}
</style>
